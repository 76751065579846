import "./App.css";
import { Grid, Typography } from '@mui/material';
import LoginScanApp from './pages/login';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import TicketScan from './pages/ticket-scan';
import ModuleSelect from './pages/module-select';
import ScanAppHeader from './components/header';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { warningToast } from './components/toast';
import { getInfo } from './api/scan-tool.api';

function App() {
  const [token, setToken] = useState('');

  if (!token) {
    return <LoginScanApp token={token} setToken={setToken} />;
  }

  return (
    <BrowserRouter>
      <div className="App">
        <ScanAppHeader />
        <Grid item xs={9} md={10}>
          <Routes>
            <Route path="/" element={<ModuleSelect token={token} />} />
            <Route
              path="/:scanModuleId"
              element={<TicketScan token={token} />}
            />
          </Routes>
        </Grid>
      </div>
    </BrowserRouter>
  );
}

export default App;
