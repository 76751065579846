import { AppBar, Toolbar, Box, Typography, useMediaQuery } from "@mui/material";
import OpenEventsLogo from "../assets/openevents_logo.svg";
function ScanAppHeader() {
  const isWideScreen = useMediaQuery("(min-width: 1000px)");
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar sx={{ height: "3em" }}>
          {isWideScreen && (
            <Box
              sx={{
                height: "3.5em",
                width: "200px",
                marginTop: "25px",
                marginLeft: "15px",
                marginRight: "15px",
                marginBottom: "20px",
              }}
            >
              <img src={OpenEventsLogo} alt="openevents-logo" width="100%" />
            </Box>
          )}
          <Typography variant="h5" component="h1">
            {`${!isWideScreen ? "OE" : ""} | SCANTOOL`}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default ScanAppHeader;
