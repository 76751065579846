import {
  Alert,
  Paper,
  Box,
  Typography,
  useMediaQuery,
  TextField,
  Stack,
  IconButton,
} from "@mui/material";
import StartIcon from "@mui/icons-material/Start";
import { useNavigate } from "react-router";
import React, { useState } from "react";
import { scanToolLogin } from "../api/scan-tool.api";
import { errorToast } from "../components/toast";
import PropTypes from "prop-types";

function LoginScanApp({
  token,
  setToken,

}: {
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  
}) {
  const isWideScreen = useMediaQuery("(min-width: 1000px)");
  const style = {
    marginTop: "3.5em",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 1,
    bgcolor: "#FFFFFF",
    width: isWideScreen ? "40%" : "100%",
    height: isWideScreen ? "40%" : "100%",
  };
  const [scanPin, setScanPin] = useState('');
  const [error, setError] = useState('');
  const scanPinSubmit = async (e: any) => {
    e.preventDefault();
    const result = await scanToolLogin(scanPin);
    if (!!result) {
      setToken(result.token);
    }
  };

  return (
    <Paper sx={style} elevation={5}>
      <Box display="flex" p={3}>
        <Stack spacing={3}>
          <Typography
            align="left"
            variant="h4"
            sx={{ fontWeight: "600", color: "#00537D" }}
          >
            Scan-PIN
          </Typography>
          <Box display="flex" alignContent="left">
            <form onSubmit={scanPinSubmit}>
              <TextField
                error={!!error}
                value={scanPin}
                label="Scan-PIN eintragen"
                type="tel"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={(e) => setScanPin(e.target.value.trim())}
              />
              <IconButton type="submit" size="large" color="primary">
                <StartIcon />
              </IconButton>
            </form>
          </Box>
          {error && <Alert severity="error">{error}</Alert>}
          <Alert severity="info">
            Der Scan-PIN ist ein 6-stelliger Code, der dir von den
            Organisator:innen der Veranstaltung zur Verfügung gestellt wird
          </Alert>
        </Stack>
      </Box>
    </Paper>
  );
}

LoginScanApp.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default LoginScanApp;
